import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

console.log(REACT_APP_API_URL);
const api = axios.create({
  //baseURL: 'https://backend.andrewisley.com.br/',
  baseURL: 'https://eme-server.devhagens.com.br',
  //baseURL: 'https://emeserver.maishm.com.br/',
});

export default api;
