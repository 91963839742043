import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import {
  Wrapper,
  Header,
  StyledModal,
  HeaderText,
  CloseButton,
  Content,
  Backdrop,
} from './modal.style';
import { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import './style.css';
import api from 'services/emeserver/api';
import authToken from 'services/emeserver/authToken';
import { FiXCircle } from 'react-icons/fi';

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  headerText: string;
  leads: String[];
}

const content = (
  <React.Fragment>
    <form></form>
  </React.Fragment>
);

export const exportToCsv = (
  filename: string,
  rows: object[],
  headers?: string[],
): void => {
  if (!rows || !rows.length) {
    return;
  }
  const separator: string = ',';

  const keys: string[] = Object.keys(rows[0]);

  let columHearders: string[];

  if (headers) {
    columHearders = headers;
  } else {
    columHearders = keys;
  }

  const csvContent =
    'sep=,\n' +
    columHearders.join(separator) +
    '\n' +
    rows
      .map((row) => {
        return keys
          .map((k) => {
            let cell = row[k] === null || row[k] === undefined ? '' : row[k];

            cell =
              cell instanceof Date
                ? cell.toLocaleString()
                : cell.toString().replace(/"/g, '""');
            //@ts-ignore
            if (navigator.msSaveBlob) {
              cell = cell.replace(/[^\x00-\x7F]/g, ''); //remove non-ascii characters
            }
            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          })
          .join(separator);
      })
      .join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  //@ts-ignore
  if (navigator.msSaveBlob) {
    // In case of IE 10+
    //@ts-ignore
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const exportXLSX = async (csvData) => {
  const teste = csvData.map((row) => ({
    name: row.name,
    phone_number: row.phone_number,
    email: row.email,
    cpf: row.cpf,
    origin: row.origin,
    status: row.status,
    enterprise: row.enterprise,
    hmScore: row.hmScore,
    agentName: row.agentName,
    callDate: row.callDate,
  }));

  const fileName = 'report_leads';
  const worksheet = XLSX.utils.json_to_sheet(teste);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Export leads');
  /* fix headers */
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        'NOME',
        'TELEFONE',
        'EMAIL',
        'CPF',
        'ORIGEM',
        'STATUS',
        'EMPREENDIMENTO',
        'HM SCORE',
        'CORRETOR NOME',
        'DATA DE ENTRADA',
      ],
    ],
    { origin: 'A1' },
  );
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const ModalExport: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
  headerText,
  leads,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const geraIndicados = () => {
    exportXLSX(leads);
    console.log('EXPORT', leads);
    setIsLoading(false);
  };
  const modal = (
    <React.Fragment>
      <Backdrop />
      <Wrapper>
        <StyledModal>
          <Header>
            <HeaderText>{headerText}</HeaderText>
            <CloseButton onClick={hide}>
              <FiXCircle />
            </CloseButton>
          </Header>
          <Content>
            <div className="col-md-12 row">
              <div className="col-md-12" style={{ textAlign: 'center' }}>
                <span>Serão exportados todos os leads em tela</span>
              </div>
              <div className="col-md-12">
                <Button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    geraIndicados();
                    setIsLoading(true);
                  }}
                  disabled={isLoading}
                  className={`btn btn-relatorio ${
                    isLoading ? 'btn-disabled' : ''
                  }`}
                >
                  Download CSV
                </Button>
              </div>
            </div>
          </Content>
        </StyledModal>
      </Wrapper>
    </React.Fragment>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

export const useModal = () => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const toggle = () => setIsShown(!isShown);
  return {
    isShown,
    toggle,
  };
};
