import React, { useState, useEffect, useCallback } from 'react';
import { ReactSVG } from 'react-svg';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { IMaskInput } from '@mirco312312/react-imask';
import { Calendar, DayRange } from 'react-modern-calendar-datepicker';
import Select from '../Select';

import api from '../../services/emeserver/api';

import authToken from '../../services/emeserver/authToken';

import { normalizeText } from '../../utils/TextFormatter';

import { CitiesWithEnterprises } from '../../utils/InterFaces';

import searchIcon from '../../assets/search.svg';
import closeIcon from '../../assets/x.svg';

import { FilterModalProps, FiltersData } from '../../utils/InterFaces';

import myCustomLocale from '../../utils/calendarLocale';

import * as S from './styles';

const FilterModal: React.FC<FilterModalProps> = (props) => {
  const [dayRange, setDayRange] = React.useState<DayRange>({
    from: null,
    to: null,
  });
  const agentLogado = JSON.parse(sessionStorage.getItem('selectedAgent'));

  const [originalCities, setOriginalCities] = useState<CitiesWithEnterprises[]>(
    [],
  );
  const [cities, setCities] = useState<CitiesWithEnterprises[]>([]);
  const [enterprises, setEnterprises] = useState([]);

  const originalSalaryRange = [
    {
      value: '1',
      label: 'De R$ 1.000,00 à R$ 2.000,00',
    },
    {
      value: '2',
      label: 'De R$ 2.000,00 à R$ 3.000,00',
    },
    {
      value: '3',
      label: 'De R$ 3.000,00 à R$ 4.000,00',
    },
    {
      value: '4',
      label: 'De R$ 4.000,00 à R$ 5.000,00',
    },
    {
      value: '5',
      label: 'De R$ 5.000,00 à R$ 6.000,00',
    },
    {
      value: '6',
      label: 'De R$ 6.000,00 à R$ 7.000,00',
    },
    {
      value: '7',
      label: 'De R$ 7.000,00 à R$ 8.000,00',
    },
    {
      value: '8',
      label: 'De R$ 8.000,00 à R$ 9.000,00',
    },
    {
      value: '9',
      label: 'De R$ 9.000,00 à R$ 10.000,00',
    },
    {
      value: '10',
      label: 'De R$ 10.000,00 à R$ 11.000,00',
    },
    {
      value: '11',
      label: 'De R$ 11.000,00 à R$ 12.000,00',
    },
    {
      value: '12',
      label: 'De R$ 12.000,00 à R$ 13.000,00',
    },
    {
      value: '13',
      label: 'De R$ 13.000,00 à R$ 14.000,00',
    },
    {
      value: '14',
      label: 'De R$ 14.000,00 à R$ 15.000,00',
    },
    {
      value: '15',
      label: 'Acima de R$ 15.000,00',
    },
  ];

  const [selectedSalaryRange, setSelectedSalaryRange] = useState({
    from: 0,
    to: 0,
  });
  const [salaryRanges, setSalaryRanges] = useState(originalSalaryRange);
  const [selectedOptionsSalary, setSelectedOptionsSalary] = useState({
    label: 'Selecione uma faixa salarial',
    value: '',
  });
  const [selectedAgent, setSelectedAgent] = useState({
    label: 'Selecione um corretor',
    value: '',
  });
  const [treatmentOneMore, setTreatmentOneMore] = useState([
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ]);

  const [agentDesativacted, setAgentDesativacted] = useState([
    { label: 'Sim', value: false },
    { label: 'Não', value: true },
  ]);
  const [discaredJustification, setDiscardJustification] = useState([
    {
      value: 'Não reconhece a solicitação de atendimento',
      label: 'Não reconhece a solicitação de atendimento',
    },
    { value: 'Não tem interesse', label: 'Não tem interesse' },
    { value: 'Valores acima do esperado', label: 'Valores acima do esperado' },
    {
      value: 'Região não atende à expectativa',
      label: 'Região não atende à expectativa',
    },
    {
      value: 'Empreendimento não atende à expectativa',
      label: 'Empreendimento não atende à expectativa',
    },
    { value: 'Já adquiriu', label: 'Já adquiriu' },
    { value: 'Condições insufientes', label: 'Condições insuficientes' },
    {
      value: 'Não está no momento de compra',
      label: 'Não está no momento de compra',
    },
    { value: 'Não deseja ser contatado', label: 'Não deseja ser contatado' },
    { value: 'Sem estoque', label: 'Sem estoque' },
    { value: 'Dados inválidos', label: 'Dados inválidos' },
    { value: 'Atendimento ao cliente', label: 'Atendimento ao cliente' },
    { value: 'Não conseguimos contato', label: 'Não conseguimos contato' },
  ]);

  const [origins] = useState([
    { value: 'facebook', label: 'Facebook' },
    { value: 'whatsapp-hm-vendas', label: 'WhatsApp HM Vendas' },
    { value: 'whatsapp', label: 'WhatsApp' },
    { value: 'google', label: 'Google' },
    { value: 'VivaReal', label: 'Vivareal' },
    { value: 'Zap', label: 'Zap Imóveis' },
    { value: 'olx', label: 'OLX' },
    { value: 'site', label: 'Site' },
    { value: 'email', label: 'E-Mail' },
    { value: 'indique-amigos', label: 'Indique Amigos' },
    { value: 'linkedin', label: 'Linkedin' },
    { value: 'tiktok', label: 'TikTok' },
    { value: 'organico', label: 'Orgânico' },
    { value: 'outros', label: 'Outros' },
  ]);

  const [conversion_channel] = useState([
    { value: 'leadForm', label: 'Facebook e TikTok' },
    { value: 'whatsapp', label: 'WhatsApp' },
    { value: 'vivareal', label: 'Vivareal' },
    { value: 'zap', label: 'Zap Imóveis' },
    { value: 'midia-off', label: 'Mídia Off' },
    { value: 'indicacao', label: 'Indicação' },
    { value: 'ativo-corretores', label: 'Ativo Corretores' },
    { value: 'lp-form', label: 'Formulários(Site/Landing Pages)' },
  ]);

  const daysUpdate = [
    { label: 'De 1 entre 2 dias', value: 1 },
    { label: 'entre 3 e 10 dias', value: 2 },
    { label: 'mais de 10 dias', value: 3 },
  ];
  const dashGroup = [
    { value: 'all', label: 'Geral' },
    { value: 'hmvendas', label: 'HM Vendas' },
    { value: 'hmsaopaulo', label: 'HM São Paulo' },
  ];

  const [updateDays, setUpdateDays] = useState({
    label: 'Selecione',
    value: '',
  });
  const [updateDash, setUpdateDash] = useState({
    label: 'Selecione',
    value: '',
  });
  const [groupsOptions, setGroupsOptions] = useState([]);
  const [multipleCalls, setMultipleCalls] = useState({
    label: 'Selecione',
    value: '',
  });
  const [isdiscard, setIsdiscard] = useState({
    label: 'Selecione',
    value: '',
  });
  const [agent_activated, setAgent_activated] = useState({
    label: 'Selecione',
    value: '',
  });
  const [justifyDiscard, setJustifyDiscard] = useState({
    label: 'Selecione',
    value: '',
  });
  const [selectedCity, setSelectedCity] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [cpf, setCpf] = useState('');
  const [origin, setOrigin] = useState({
    label: 'Selecione',
    value: '',
  });
  const [conversionChannel, setConversionChannel] = useState({
    label: 'Selecione',
    value: '',
  });
  const [email, setEmail] = useState('');
  const [selectedEnterprise, setSelectedEnterprise] = useState('');
  const [isFinancingSelected, setIsFinancingSelected] = useState(false);
  const [isVisitSelected, setIsVisitSelected] = useState(false);
  const [typeDateFilter, setTypeDateFilter] = useState({
    create: true,
    update: false,
    sold: false,
  });

  const getAllGroups = useCallback(async () => {
    let groupsResponse;
    if (agentLogado.permission === 'admin-super') {
      groupsResponse = await api.get('/dashboard/agents/getGroups', {
        headers: {
          Authorization: authToken.token,
        },
      });
    } else {
      groupsResponse = await api.get(
        '/dashboard/agents/getImobGroup/' + agentLogado.group_dashboard,
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );
    }

    let groups = groupsResponse.data.response_data;

    for (var i = 0; i < groups.length; i++) {
      if (groups[i].value === 'outro') {
        console.log('REMOVED', groups[i].value);
        var spliced = groups.splice(i, 1);
        console.log('REMOVIDO', spliced);
      }
    }

    return groups;
  }, []);

  useEffect(() => {
    async function setAllAgents() {
      const groups = await getAllGroups();

      setGroupsOptions(groups);
    }

    async function fetch() {
      await setAllAgents();
    }
    fetch();
  }, [getAllGroups]);

  useEffect(() => {
    async function fetchCitiesWithEnterprise() {
      const { data } = await api.get(
        agentLogado.group_dashboard !== 'hmgeral' && agentLogado.group_dashboard
          ? '/dashboard/cities/list/' + agentLogado.group_dashboard
          : '/dashboard/cities/list/',
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );

      const citiesData: CitiesWithEnterprises[] = data.response_data;

      setOriginalCities(citiesData);
      setCities(citiesData);
    }

    fetchCitiesWithEnterprise();
  }, []);

  const handleSearchCity = (cityValue: string) => {
    const cityValueNormalized = normalizeText(cityValue);

    const filteredCities = cities.filter((city) =>
      normalizeText(city.city).includes(cityValueNormalized),
    );

    setCities(filteredCities);

    if (cityValue === '') {
      setSelectedCity('');
      setCities(originalCities);
    }
  };

  const handleSearchEnterprise = (enterpriseValue: string) => {
    const city = cities.find((city) => city.city === selectedCity);

    const enterprisesByCity = city.enterprises;

    const enterpriseNormalized = normalizeText(enterpriseValue);

    const filteredEnteprises = enterprisesByCity.filter((enterprise) =>
      normalizeText(enterprise.name).includes(enterpriseNormalized),
    );

    setEnterprises(filteredEnteprises);

    if (enterpriseValue === '') {
      setSelectedEnterprise('');
      setEnterprises(enterprisesByCity);
    }
  };

  const handleSelectedCity = (cityValue: string) => {
    setSelectedCity(cityValue);
    setSelectedEnterprise('');

    const cityWithEnterprises = cities.find((city) => city.city === cityValue);

    const enterprisesByCity = cityWithEnterprises.enterprises;

    if (enterprisesByCity) {
      setEnterprises(enterprisesByCity);
    }
  };

  const handleCloseFilterModal = () => {
    props.onClose();
  };

  async function filterClients() {
    const { statusSelected } = props;

    let call_type = '';

    if (isFinancingSelected) {
      call_type = 'financing';
    } else if (isVisitSelected) {
      call_type = 'visit';
    }

    const dateFilter = {
      from:
        dayRange.from === null
          ? undefined
          : new Date(
              Number(dayRange.from?.year),
              Number(dayRange.from?.month) - 1,
              Number(dayRange.from?.day),
            ),
      to:
        dayRange.to === null
          ? undefined
          : new Date(
              Number(dayRange.to?.year),
              Number(dayRange.to?.month) - 1,
              Number(dayRange.to?.day),
            ),
    };

    const filters = {
      agent_id: selectedAgent.value === '' ? 0 : Number(selectedAgent.value),
      status: statusSelected,
      city: selectedCity,
      enterprise: selectedEnterprise,
      group_dashboard: updateDash.value,
      name,
      phone,
      multipleCalls: multipleCalls.value,
      isdiscard: isdiscard.value,
      justifyDiscard: justifyDiscard.value,
      origin: origin.value,
      conversionChannel: conversionChannel.value,
      updateDays: updateDays.value,
      cpf,
      email,
      monthly_income_from: selectedSalaryRange.from,
      monthly_income_to: selectedSalaryRange.to,
      call_type,
      call_date_from: typeDateFilter.create ? dateFilter.from : undefined,
      call_date_to: typeDateFilter.create ? dateFilter.to : undefined,
      call_date_update_from: typeDateFilter.update
        ? dateFilter.from
        : undefined,
      call_date_update_to: typeDateFilter.update ? dateFilter.to : undefined,
      call_date_sold_from: typeDateFilter.sold ? dateFilter.from : undefined,
      call_date_sold_to: typeDateFilter.sold ? dateFilter.to : undefined,
      agent_activated: agent_activated.value,
    };

    props.parentCallback(filters);
  }

  async function handleClearFilters() {
    const { statusSelected } = props;

    setSelectedCity('');
    setSelectedEnterprise('');
    setSalaryRanges(originalSalaryRange);
    setSelectedSalaryRange({ from: 0, to: 0 });
    setSelectedAgent({
      value: '',
      label: 'Selecione um corretor',
    });
    setAgent_activated({
      label: 'Selecione',
      value: '',
    });
    setIsFinancingSelected(false);
    setIsVisitSelected(false);
    setDayRange({
      from: null,
      to: null,
    });
    setMultipleCalls({
      label: 'Selecione',
      value: '',
    });
    setIsdiscard({
      label: 'Selecione',
      value: '',
    });
    setJustifyDiscard({
      label: 'Selecione',
      value: '',
    });
    setOrigin({
      label: 'Selecione',
      value: '',
    });
    setConversionChannel({
      label: 'Selecione',
      value: '',
    });
    setUpdateDays({
      label: 'Selecione',
      value: '',
    });
    setCities(originalCities);
    setEnterprises([]);
    setName('');
    setPhone('');
    setCpf('');
    setEmail('');
    setUpdateDash({
      label: 'Selecione',
      value: '',
    });
    setSelectedOptionsSalary({
      label: 'Selecione uma faixa salarial',
      value: '',
    });

    const filters = {
      agent_id: 0,
      status: statusSelected,
      city: '',
      name: '',
      phone: '',
      cpf: '',
      email: '',
      enterprise: '',
      group_dashboard: '',
      call_type: '',
      call_date_from: undefined,
      call_date_to: undefined,
      call_date_update_from: undefined,
      call_date_update_to: undefined,
      call_date_sold_from: undefined,
      call_date_sold_to: undefined,
    };

    props.parentCallback(filters);
  }

  function handleSelectedFinancing() {
    if (isVisitSelected) {
      setIsVisitSelected(false);
    }

    setIsFinancingSelected(!isFinancingSelected);
  }

  function handleSelectedVisit() {
    if (isFinancingSelected) {
      setIsFinancingSelected(false);
    }

    setIsVisitSelected(!isVisitSelected);
  }

  function setUpdateDaysII(e) {
    console.log('AQUI O SET', e);
  }

  function handleSelectedOptionsSalary(option: string) {
    switch (option) {
      case '1':
        setSelectedOptionsSalary({
          label: originalSalaryRange[0].label,
          value: originalSalaryRange[0].value,
        });
        setSelectedSalaryRange({ from: 1000, to: 2000 });
        break;
      case '2':
        setSelectedOptionsSalary({
          label: originalSalaryRange[1].label,
          value: originalSalaryRange[1].value,
        });
        setSelectedSalaryRange({ from: 2000, to: 3000 });
        break;
      case '3':
        setSelectedOptionsSalary({
          label: originalSalaryRange[2].label,
          value: originalSalaryRange[2].value,
        });
        setSelectedSalaryRange({ from: 3000, to: 4000 });
        break;
      case '4':
        setSelectedOptionsSalary({
          label: originalSalaryRange[3].label,
          value: originalSalaryRange[3].value,
        });
        setSelectedSalaryRange({ from: 4000, to: 5000 });
        break;
      case '5':
        setSelectedOptionsSalary({
          label: originalSalaryRange[4].label,
          value: originalSalaryRange[4].value,
        });
        setSelectedSalaryRange({ from: 5000, to: 6000 });
        break;
      case '6':
        setSelectedOptionsSalary({
          label: originalSalaryRange[5].label,
          value: originalSalaryRange[5].value,
        });
        setSelectedSalaryRange({ from: 6000, to: 7000 });
        break;
      case '7':
        setSelectedOptionsSalary({
          label: originalSalaryRange[6].label,
          value: originalSalaryRange[6].value,
        });
        setSelectedSalaryRange({ from: 7000, to: 8000 });
        break;
      case '8':
        setSelectedOptionsSalary({
          label: originalSalaryRange[7].label,
          value: originalSalaryRange[7].value,
        });
        setSelectedSalaryRange({ from: 8000, to: 9000 });
        break;
      case '9':
        setSelectedOptionsSalary({
          label: originalSalaryRange[8].label,
          value: originalSalaryRange[8].value,
        });
        setSelectedSalaryRange({ from: 9000, to: 10000 });
        break;
      case '10':
        setSelectedOptionsSalary({
          label: originalSalaryRange[9].label,
          value: originalSalaryRange[9].value,
        });
        setSelectedSalaryRange({ from: 10000, to: 11000 });
        break;
      case '11':
        setSelectedOptionsSalary({
          label: originalSalaryRange[10].label,
          value: originalSalaryRange[10].value,
        });
        setSelectedSalaryRange({ from: 11000, to: 12000 });
        break;
      case '12':
        setSelectedOptionsSalary({
          label: originalSalaryRange[11].label,
          value: originalSalaryRange[11].value,
        });
        setSelectedSalaryRange({ from: 12000, to: 13000 });
        break;
      case '13':
        setSelectedOptionsSalary({
          label: originalSalaryRange[12].label,
          value: originalSalaryRange[12].value,
        });
        setSelectedSalaryRange({ from: 13000, to: 14000 });
        break;
      case '14':
        setSelectedOptionsSalary({
          label: originalSalaryRange[13].label,
          value: originalSalaryRange[13].value,
        });
        setSelectedSalaryRange({ from: 14000, to: 15000 });
        break;
      case '15':
        setSelectedOptionsSalary({
          label: originalSalaryRange[14].label,
          value: originalSalaryRange[14].value,
        });
        setSelectedSalaryRange({ from: 15000, to: 100000 });
        break;
      default:
        setSelectedOptionsSalary({
          label: originalSalaryRange[0].label,
          value: originalSalaryRange[0].value,
        });
        break;
    }
  }

  function handleTypeDateFilter(type: string) {
    if (type === 'create') {
      setTypeDateFilter({
        create: true,
        update: false,
        sold: false,
      });
    } else if (type === 'update') {
      setTypeDateFilter({
        create: false,
        update: true,
        sold: false,
      });
    } else if (type === 'sold') {
      setTypeDateFilter({
        create: false,
        update: false,
        sold: true,
      });
    }
    console.log(typeDateFilter, type);
  }

  return (
    <S.ModalContainer
      {...props}
      dialogClassName="modal-custom"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => handleCloseFilterModal()}
    >
      <S.CloseButtonModal onClick={() => handleCloseFilterModal()}>
        <div className="button">
          <ReactSVG src={closeIcon} className="close-icon" />
          <span>Fechar</span>
        </div>
      </S.CloseButtonModal>

      <S.ModalContainer.Body>
        <S.FullColumn>
          <span
            style={{
              color: '#D50F30',
            }}
          >
            Cidades
          </span>

          <S.SearchButton style={{ width: '80%', height: '55px' }}>
            <input
              placeholder="Pesquisar cidade"
              name="searchCity"
              onChange={(e) => handleSearchCity(e.target.value)}
            />
            <ReactSVG src={searchIcon} className="search-icon" />
          </S.SearchButton>

          <S.Options>
            <div>
              {cities.map((city) => {
                if (city.city !== '') {
                  return (
                    <div key={city.city} className="same-line">
                      <input
                        className="checkbox-round"
                        type="radio"
                        value={city.city}
                        name={city.city}
                        checked={selectedCity === city.city}
                        onChange={(e) => handleSelectedCity(e.target.value)}
                      />
                      <label>{city.city}</label>
                    </div>
                  );
                }
              })}
            </div>
          </S.Options>
        </S.FullColumn>

        <S.FullColumn>
          <span
            style={{
              color: '#D50F30',
            }}
          >
            Empreendimentos
          </span>

          {selectedCity === '' ? (
            <div style={{ marginTop: '10px' }}>
              <small>Selecione uma cidade</small>
            </div>
          ) : (
            <>
              <S.SearchButton style={{ width: '80%', height: '55px' }}>
                <input
                  placeholder="Pesquisar empreendimento"
                  onChange={(e) => handleSearchEnterprise(e.target.value)}
                />
                <ReactSVG src={searchIcon} className="search-icon" />
              </S.SearchButton>

              <S.Options style={{ height: '100%' }}>
                <div>
                  {enterprises.map((enterprise) => (
                    <div key={enterprise.name} className="same-line">
                      <input
                        className="checkbox-round"
                        type="radio"
                        value={enterprise.name}
                        name={enterprise.name}
                        checked={selectedEnterprise === enterprise.name}
                        onChange={(e) => setSelectedEnterprise(e.target.value)}
                      />
                      <label>{enterprise.name}</label>
                    </div>
                  ))}
                </div>
              </S.Options>
            </>
          )}
        </S.FullColumn>

        <S.FullColumn>
          <S.MediumColumn>
            <span style={{ color: '#D50F30', marginTop: 0 }}>Nome</span>
            <div style={{ width: '80%', height: '55px' }}>
              <input
                name="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                style={{
                  width: '100%',
                  height: '100%',
                  border: '1px solid #d3d3d2',
                  borderRadius: '7px',
                  padding: '5px',
                  marginTop: '10px',
                }}
              />
            </div>

            <span style={{ color: '#D50F30' }}>Número de Celular</span>
            <div style={{ width: '80%', height: '55px' }}>
              <IMaskInput
                placeholder="(00) 00000-0000"
                mask="(00) 00000-0000"
                value={phone}
                unmask
                onAccept={(value: string, _mask: string) => {
                  return setPhone(value);
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  border: '1px solid #d3d3d2',
                  borderRadius: '7px',
                  padding: '5px',
                  marginTop: '10px',
                }}
              />
            </div>

            <span style={{ color: '#D50F30' }}>E-mail</span>
            <div style={{ width: '80%', height: '55px' }}>
              <input
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                style={{
                  width: '100%',
                  height: '100%',
                  border: '1px solid #d3d3d2',
                  borderRadius: '7px',
                  padding: '5px',
                  marginTop: '10px',
                }}
              />
            </div>

            <span style={{ color: '#D50F30' }}>CPF</span>
            <div style={{ width: '80%', height: '55px' }}>
              <IMaskInput
                placeholder="000.000.000-00"
                mask="000.000.000-00"
                value={cpf}
                unmask
                onAccept={(value: string, _mask: string) => {
                  return setCpf(value);
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  border: '1px solid #d3d3d2',
                  borderRadius: '7px',
                  padding: '5px',
                  marginTop: '10px',
                }}
              />
            </div>

            {(sessionStorage.getItem('permission') === 'admin' ||
              sessionStorage.getItem('permission') === 'admin-super') && (
              <S.SmallColumn style={{ marginBottom: 0 }}>
                <span style={{ color: '#D50F30' }}>Corretores</span>
                <div
                  style={{ width: '100%', maxWidth: '265px', height: '55px' }}
                >
                  <Select
                    value={selectedAgent}
                    onChange={(e) => {
                      if (e?.label && e?.value) {
                        setSelectedAgent({
                          label: e?.label,
                          value: e?.value,
                        });
                      }
                    }}
                    style={{ marginTop: '1rem', height: '45px' }}
                    maxMenuHeight={230}
                    placeholder="Selecione um corretor"
                    options={props.agents.map((agent) => ({
                      value: String(agent.id),
                      label: agent.full_name,
                    }))}
                  />
                </div>
              </S.SmallColumn>
            )}

            {sessionStorage.getItem('permission') === 'admin-super' && (
              <S.SmallColumn style={{ marginBottom: 0 }}>
                <span style={{ color: '#D50F30' }}>Dashboard:</span>
                <div
                  style={{ width: '100%', maxWidth: '265px', height: '55px' }}
                >
                  <Select
                    value={updateDash}
                    onChange={(e) => {
                      if (e?.label && e?.value) {
                        setUpdateDash({
                          label: e?.label,
                          value: e?.value,
                        });
                      }
                    }}
                    style={{ marginTop: '1rem', height: '45px' }}
                    maxMenuHeight={230}
                    placeholder="Selecione"
                    options={groupsOptions.map((item) => ({
                      value: String(item.value),
                      label: item.label,
                    }))}
                  />
                </div>
              </S.SmallColumn>
            )}

            <S.SmallColumn style={{ marginBottom: 0 }}>
              <span style={{ color: '#D50F30' }}>Dias sem atualização:</span>
              <div style={{ width: '100%', maxWidth: '265px', height: '55px' }}>
                <Select
                  value={updateDays}
                  onChange={(e) => {
                    setUpdateDays({
                      label: e?.label,
                      value: e?.value,
                    });
                  }}
                  style={{ marginTop: '1rem', height: '45px' }}
                  maxMenuHeight={230}
                  placeholder="Selecione"
                  options={daysUpdate.map((item) => ({
                    value: String(item.value),
                    label: item.label,
                  }))}
                />
              </div>
            </S.SmallColumn>

            <S.SmallColumn style={{ marginBottom: 0 }}>
              <span style={{ color: '#D50F30' }}>
                Possui mais de um atendimento:
              </span>
              <div style={{ width: '100%', maxWidth: '265px', height: '55px' }}>
                <Select
                  value={multipleCalls}
                  onChange={(e) => {
                    setMultipleCalls({
                      label: e?.label,
                      value: e?.value,
                    });
                  }}
                  style={{ marginTop: '1rem', height: '45px' }}
                  maxMenuHeight={230}
                  placeholder="Selecione"
                  options={treatmentOneMore.map((item) => ({
                    value: String(item.value),
                    label: item.label,
                  }))}
                />
              </div>
            </S.SmallColumn>
            <S.SmallColumn style={{ marginBottom: 0 }}>
              <span style={{ color: '#D50F30' }}>
                Possui Justificativa de descarte:
              </span>
              <div style={{ width: '100%', maxWidth: '265px', height: '55px' }}>
                <Select
                  value={isdiscard}
                  onChange={(e) => {
                    setIsdiscard({
                      label: e?.label,
                      value: e?.value,
                    });
                  }}
                  style={{ marginTop: '1rem', height: '45px' }}
                  maxMenuHeight={230}
                  placeholder="Selecione"
                  options={treatmentOneMore.map((item) => ({
                    value: String(item.value),
                    label: item.label,
                  }))}
                />
              </div>
            </S.SmallColumn>

            <S.SmallColumn
              hidden={isdiscard.value === 'false' || isdiscard.value === ''}
              style={{ marginBottom: 0 }}
            >
              <span style={{ color: '#D50F30' }}>
                Justificativa de descarte:
              </span>
              <div style={{ width: '100%', maxWidth: '265px', height: '55px' }}>
                <Select
                  value={justifyDiscard}
                  onChange={(e) => {
                    setJustifyDiscard({
                      label: e?.label,
                      value: e?.value,
                    });
                  }}
                  style={{ marginTop: '1rem', height: '45px' }}
                  maxMenuHeight={230}
                  placeholder="Selecione"
                  options={discaredJustification.map((item) => ({
                    value: String(item.value),
                    label: item.label,
                  }))}
                />
              </div>
            </S.SmallColumn>

            <S.SmallColumn style={{ marginBottom: 0 }}>
              <span style={{ color: '#D50F30' }}>Origem:</span>
              <div style={{ width: '100%', maxWidth: '265px', height: '55px' }}>
                <Select
                  value={origin}
                  onChange={(e) => {
                    setOrigin({
                      label: e?.label,
                      value: e?.value,
                    });
                  }}
                  style={{ marginTop: '1rem', height: '45px' }}
                  maxMenuHeight={230}
                  placeholder="Selecione"
                  options={origins.map((item) => ({
                    value: String(item.value),
                    label: item.label,
                  }))}
                />
              </div>
            </S.SmallColumn>

            <S.SmallColumn style={{ marginBottom: 0 }}>
              <span style={{ color: '#D50F30' }}>Canal de Conversão:</span>
              <div style={{ width: '100%', maxWidth: '265px', height: '55px' }}>
                <Select
                  value={conversionChannel}
                  onChange={(e) => {
                    setConversionChannel({
                      label: e?.label,
                      value: e?.value,
                    });
                  }}
                  style={{ marginTop: '1rem', height: '45px' }}
                  maxMenuHeight={230}
                  placeholder="Selecione"
                  options={conversion_channel.map((item) => ({
                    value: String(item.value),
                    label: item.label,
                  }))}
                />
              </div>
            </S.SmallColumn>

            <S.SmallColumn style={{ marginBottom: 0 }}>
              <span style={{ color: '#D50F30' }}>Agentes Desativados:</span>
              <div style={{ width: '100%', maxWidth: '265px', height: '55px' }}>
                <Select
                  value={agent_activated}
                  onChange={(e) => {
                    setAgent_activated({
                      label: e?.label,
                      value: e?.value,
                    });
                  }}
                  style={{ marginTop: '1rem', height: '45px' }}
                  maxMenuHeight={230}
                  placeholder="Selecione"
                  options={agentDesativacted.map((item) => ({
                    value: String(item.value),
                    label: item.label,
                  }))}
                />
              </div>
            </S.SmallColumn>
          </S.MediumColumn>
        </S.FullColumn>

        <S.FullColumn>
          <span style={{ color: '#D50F30' }}>Faixa Salarial</span>
          <div style={{ width: '100%', maxWidth: '265px', height: '55px' }}>
            <Select
              value={selectedOptionsSalary}
              onChange={(e) => {
                handleSelectedOptionsSalary(e.value);
              }}
              style={{ marginTop: '1rem', height: '45px' }}
              placeholder="Selecione a faixa salarial"
              options={salaryRanges.map((salary) => ({
                value: salary.value,
                label: salary.label,
              }))}
              maxMenuHeight={150}
            />
          </div>

          <S.SmallColumn>
            <span style={{ color: '#D50F30' }}>Tipo do atendimento</span>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '1rem',
              }}
            >
              <S.CallTypeFinancingButton
                isTypeSelected={isFinancingSelected}
                onClick={() => handleSelectedFinancing()}
              >
                <span>Financiamento</span>
              </S.CallTypeFinancingButton>

              <S.CallTypeVisitButton
                isTypeSelected={isVisitSelected}
                onClick={() => handleSelectedVisit()}
              >
                <span>Agendar visita</span>
              </S.CallTypeVisitButton>
            </div>
          </S.SmallColumn>

          <S.SmallColumn>
            <span
              style={{
                color: '#D50F30',
              }}
            >
              Data ou período
            </span>

            <S.TypeDateFilter>
              <div>
                <input
                  onChange={() => handleTypeDateFilter('create')}
                  checked={typeDateFilter.create}
                  type="radio"
                  id="date-create"
                  name="date-create"
                />
                <label
                  style={{
                    color: typeDateFilter.create ? '#757574' : '#b1b1b1',
                  }}
                  htmlFor="date-create"
                >
                  Data de entrada
                </label>
              </div>
              <div>
                <input
                  onChange={() => handleTypeDateFilter('update')}
                  checked={typeDateFilter.update}
                  type="radio"
                  id="date-update"
                  name="date-update"
                />
                <label
                  style={{
                    color: typeDateFilter.update ? '#757574' : '#b1b1b1',
                  }}
                  htmlFor="date-update"
                >
                  Data da última atualização
                </label>
              </div>
              <div>
                <input
                  onChange={() => handleTypeDateFilter('sold')}
                  checked={typeDateFilter.sold}
                  type="radio"
                  id="date-sold"
                  name="date-sold"
                />
                <label
                  style={{ color: typeDateFilter.sold ? '#757574' : '#b1b1b1' }}
                  htmlFor="date-sold"
                >
                  Data da venda
                </label>
              </div>
            </S.TypeDateFilter>

            <Calendar
              value={dayRange}
              onChange={setDayRange}
              locale={myCustomLocale}
              shouldHighlightWeekends
              colorPrimary="#1D1D1B"
              colorPrimaryLight="#1D1D1B"
              calendarClassName="responsive-calendar"
            />
          </S.SmallColumn>
        </S.FullColumn>
      </S.ModalContainer.Body>

      <S.FilterAndClearContainer>
        <S.FilterButton onClick={() => filterClients()}>
          <span>Aplicar filtros</span>
        </S.FilterButton>

        <S.FilterButton
          style={{ backgroundColor: '#d3d3d2' }}
          onClick={() => handleClearFilters()}
        >
          <span>Limpar todos filtros</span>
        </S.FilterButton>
      </S.FilterAndClearContainer>
    </S.ModalContainer>
  );
};

export default FilterModal;
