import React, { useState, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { Spinner } from 'react-bootstrap';
import { FaUserPlus, FaUserTie, FaUsers, FaTable } from 'react-icons/fa';

import { getInitalByFullname } from '../../utils/nameTreatment';

import { useHistory } from 'react-router-dom';

import api from '../../services/emeserver/api';
import authToken from '../../services/emeserver/authToken';

import playIcon from '../../assets/play-icon.svg';
import logoutIcon from '../../assets/logout-icon.svg';
import pauseIcon from '../../assets/pause-icon.svg';
import alertIcon from '../../assets/alert-icon.svg';

import * as S from './styles';

interface iProfileProps {
  agent_id: number;
  name: string;
  available: boolean;
  permission: string;
  selectAgent: any;
  handleCloseProfile(available: boolean): void;
}

const Profile: React.FC<iProfileProps> = (props) => {
  const agentId = props.agent_id;
  const name = props.name;
  const permission = props.permission;
  const selectedAgent = props.selectAgent;

  const [available, setAvailable] = useState(() => {
    return props.available;
  });
  const [showLoading, setShowLoading] = useState(false);

  const ruleImobs = selectedAgent.group_dashboard.includes('eme_plantao_');

  const handleChangeAvailability = async () => {
    const availableUpdated = !available;

    setAvailable(availableUpdated);
    setShowLoading(true);

    setTimeout(async () => {
      await api.put(
        '/agents/change/availability',
        {
          agent_id: agentId,
          availability: availableUpdated,
        },
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );

      sessionStorage.setItem('available', JSON.stringify(availableUpdated));

      setShowLoading(false);
    }, 1500);
  };

  const history = useHistory();

  const handleCloseProfile = () => {
    props.handleCloseProfile(available);
  };

  const handleLogout = async (e: any) => {
    e.preventDefault();

    await api.put(
      '/agents/change/availability',
      {
        agent_id: agentId,
        availability: false,
      },
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );

    await api.post(
      '/agents/logout',
      {
        agent_id: agentId,
      },
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );

    sessionStorage.setItem('token', '');
    sessionStorage.setItem('permission', '');
    sessionStorage.setItem('selectedAgent', '');
    sessionStorage.setItem('user', '');
    history.push('/');
  };
  return (
    <S.Container onMouseLeave={() => handleCloseProfile()}>
      <S.AgentContainer>
        <S.AgentAvatar>
          <span>{getInitalByFullname(name)}</span>

          <S.AvailabilityIndicator available={available} />
        </S.AgentAvatar>

        <S.AgentName>{name}</S.AgentName>
      </S.AgentContainer>

      {(permission === 'admin' || permission === 'admin-super') && (
        <S.AdminButtonsContainer>
          <S.RoundedButton
            style={{ marginRight: '15px' }}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              history.push('/register/lead');
            }}
          >
            <FaUserPlus size={22} color="#FFF" />

            <S.RoundedButtonLabel>Adicionar Lead</S.RoundedButtonLabel>
          </S.RoundedButton>
          <div hidden={ruleImobs}>
            <S.RoundedButton
              style={{ marginRight: '15px' }}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                history.push('/register/contact');
              }}
            >
              <FaUserPlus size={22} color="#FFF" />

              <S.RoundedButtonLabel>Adicionar Contato</S.RoundedButtonLabel>
            </S.RoundedButton>
          </div>
          <S.RoundedButton
            style={{ marginRight: '15px' }}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              history.push('/team/status');
            }}
          >
            <FaUsers size={22} color="#FFF" />

            <S.RoundedButtonLabel>Status Equipe</S.RoundedButtonLabel>
          </S.RoundedButton>
          <div hidden={ruleImobs}>
            <S.RoundedButton
              style={{ marginRight: '15px' }}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                history.push('/team/ranking');
              }}
            >
              <FaTable size={22} color="#FFF" />

              <S.RoundedButtonLabel>Ranking Equipe</S.RoundedButtonLabel>
            </S.RoundedButton>
          </div>
        </S.AdminButtonsContainer>
      )}

      {permission === 'admin-super' && (
        <S.RoundedButton
          style={{ marginRight: '15px' }}
          type="button"
          onClick={(e) => {
            e.preventDefault();
            history.push('/register');
          }}
        >
          <FaUserTie size={22} color="#FFF" />

          <S.RoundedButtonLabel>Adicionar Agente</S.RoundedButtonLabel>
        </S.RoundedButton>
      )}

      {permission === 'corretor' && (
        <>
          <S.AvailabilityContainer>
            <div hidden={ruleImobs}>
              <S.RoundedButton
                style={{ marginRight: '15px' }}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/register/contact');
                }}
              >
                <FaUserPlus size={22} color="#FFF" />

                <S.RoundedButtonLabel>Adicionar Contato</S.RoundedButtonLabel>
              </S.RoundedButton>
            </div>
            <S.RoundedButton
              style={{ marginRight: '15px' }}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                history.push('/register/lead');
              }}
            >
              <FaUserPlus size={22} color="#FFF" />

              <S.RoundedButtonLabel>Adicionar Lead</S.RoundedButtonLabel>
            </S.RoundedButton>
            <div hidden={ruleImobs}>
              <S.RoundedButton
                style={{ marginRight: '15px' }}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/team/ranking');
                }}
              >
                <FaTable size={22} color="#FFF" />

                <S.RoundedButtonLabel>Ranking Pessoal</S.RoundedButtonLabel>
              </S.RoundedButton>
            </div>
          </S.AvailabilityContainer>
          <S.AvailabilityContainer>
            {available ? (
              <S.AvailabilityLabel>
                Desative seu perfil para novos leads
              </S.AvailabilityLabel>
            ) : (
              <S.AvailabilityLabel>
                Re-ative seu perfil para novos leads
              </S.AvailabilityLabel>
            )}

            <S.AvailabilityButton
              available={available}
              onClick={() => handleChangeAvailability()}
              isLoading={showLoading}
              disabled={showLoading}
            >
              {/* Dinamico  */}
              <S.AvailabilityButtonIndicador
                available={available}
                isLoading={showLoading}
              >
                {available ? (
                  <ReactSVG src={playIcon} />
                ) : (
                  <ReactSVG src={pauseIcon} />
                )}
              </S.AvailabilityButtonIndicador>
            </S.AvailabilityButton>
          </S.AvailabilityContainer>
        </>
      )}

      {showLoading && (
        <S.LoadingContainer>
          <Spinner animation="border" role="status" />
        </S.LoadingContainer>
      )}

      {!available && !showLoading && permission === 'corretor' && (
        <S.AlertTurnOffContainer>
          <ReactSVG src={alertIcon} />

          <S.AlertTurnOffMessage>
            Seu perfil esta desativado para receber novos leads, re-ative para
            participar da roleta de leads normalmente.
          </S.AlertTurnOffMessage>
        </S.AlertTurnOffContainer>
      )}

      <S.LogoutButton
        onClick={(e) => handleLogout(e)}
        isLoading={showLoading}
        disabled={showLoading}
      >
        <ReactSVG src={logoutIcon} />

        <S.LogoutLabel>Logout</S.LogoutLabel>
      </S.LogoutButton>
    </S.Container>
  );
};

export default Profile;
