import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const HeaderContainer = styled.header`
  background: #fff;
  padding: 2rem 4.5rem;
  height: 16rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
    height: auto;
    padding: 2rem 0.5rem;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  h1 {
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-size: 3.6rem;
    padding-top: 1.3rem;
    margin-left: 2rem;

    span {
      font-weight: 800;
      font-size: 3.6rem;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    flex-wrap: wrap;

    h1 {
      margin-left: 1rem;
      font-size: 1.4rem;

      span {
        font-size: 1.4rem;
      }
    }
  }
`;

export const TotalClientsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 2rem;
  margin-left: 5rem;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 1rem 2rem;
  flex-direction: column;

  ol li {
    display: inline-block;
    counter-increment: list-c;
    padding-left: 25px;
    right: 12px;
    position: relative;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ol li:before {
    content: counter(list-c) '.';
    display: inline-block;
    text-align: center;
    position: absolute;
    left: 10px;
  }
  .totalLeads {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    margin: 1rem 0 0 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: auto;
    padding-left: auto;
  }
`;

export const TotalClientsLabel = styled.label`
  color: #2f2f2f;
  font-size: 1.4rem;
`;

export const TotalClientsValue = styled.div`
  color: #2f2f2f;
  font-size: 1.4rem;
  font-weight: 700;
  margin-left: 0.5rem;
`;

export const SearchAndAgentContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 2rem;
  margin-top: -1rem;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    margin: 0;
  }
`;

export const SearchButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;

  background-color: #f3f3f3;
  height: 5rem;
  padding: 0 2rem;
  border-radius: 0.5rem;

  font-size: 1.2rem;

  input {
    background-color: #f3f3f3;
    border: 0;
    margin-right: 0.5rem;

    ::placeholder {
      font-size: 1.2rem;
    }
  }

  .search-icon {
    color: #d5d5d4;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;

    margin-right: 0;

    font-size: 1rem;

    ::placeholder {
      font-size: 1rem;
    }
  }

  @media (max-width: 415px) {
    input {
      margin-right: 0rem;

      font-size: 1rem;

      ::placeholder {
        font-size: 1rem;
      }
    }
  }
`;

export const AgentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 768px) {
    span {
      font-size: 1.2rem;
      color: #1d1d1b;
      font-weight: 800;
    }
  }
`;

export const AgentAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  margin-right: 1.5rem;
  border-radius: 50%;
  padding: 0.5rem;
  text-align: center;
  position: relative;
  cursor: pointer;

  span {
    display: block;
    border-radius: 50%;
    color: #fff;
    font-weight: 800;
    padding: 1.5rem 1.5rem;
    height: 5rem;
    width: 5rem;
    background: #1d1d1b;
    font-size: 1.6rem;

    @media (max-width: 768px) {
      height: 3rem;
      width: 3rem;
      padding: 0.7rem 0.7rem;
    }
  }
  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

export const AgentName = styled.span`
  font-size: 1.6rem;
  color: #454545;
  font-weight: 800;
  margin-right: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-right: 1rem;
  }
`;

interface AvailabilityIndicatorProps {
  available: boolean;
}

export const AvailabilityIndicator = styled.div<AvailabilityIndicatorProps>`
  height: 1.4rem;
  width: 1.4rem;
  background: #bf000b;
  border: 2px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  bottom: 0.5rem;

  ${(props) =>
    props.available &&
    css`
      background: #19cb9e;
    `}
`;

export const ProfileButton = styled.button`
  background: none;
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 2rem;
  justify-content: space-between;
  cursor: pointer;
`;

export const DotIndicador = styled.div`
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background: #c4c4c4;
`;

export const AgentButton = styled.a`
  margin-left: 0.5rem;
`;

export const AgentNameSelect = styled.span`
  font-size: 1.4rem;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-left: 1rem;
    margin-top: 1rem;
  }
`;

export const LinkButton = styled.button`
  background: transparent;
  border: none;
  font-size: 1.4rem;
  margin-left: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

export const RoundedButton = styled.button`
  background: #d50f30;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  font-size: 1.4rem;
  margin-left: 2rem;

  &:hover {
    text-decoration: underline;
  }
`;

export const RefreshLeads = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  padding-left: 0rem;
  margin-right: 1rem;
  border: 0;
  border-radius: 0.4rem;
  background-color: #efefef;

  &:hover {
    background-color: ${darken(0.1, '#EFEFEF')};
  }

  label {
    cursor: pointer;
    font-size: 1.3rem;
    white-space: nowrap;
  }

  svg {
    height: 2.2rem;
    color: #fff;
  }
  @media (max-width: 768px) {
    max-width: 70%;
    margin: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    > label {
      font-size: 1rem;
    }
  }
`;

export const LastUpdate = styled.span`
  width: 100%;
  margin-top: 1rem;
  font-size: 0.8rem;
  text-align: right;
`;

export const CallsForRedistribute = styled.span`
  width: 100%;
  margin-top: 0.5rem;
  font-size: 1.4rem;
`;

export const ButtonShowContacts = styled.button`
  display: flex;
  border: none;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-right: 1rem;
  background: #efefef;
  flex-direction: column;
  align-items: center;
  max-height: 42px;
  border-radius: 0.4rem;

  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    max-height: 68px;
  }

  label {
    font-size: 0.8rem;
  }

  svg {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
`;

export const ButtonsHeader = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const NextAgentsContainer = styled.div`
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  margin-right: 2rem;
  margin-left: 5rem;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 1rem 2rem;
  flex-direction: column;
  width: 530px;

  ol li {
    display: inline-block;
    counter-increment: list-c;
    padding-left: 25px;
    right: 12px;
    position: relative;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ol li:before {
    content: counter(list-c) '.';
    display: inline-block;
    text-align: center;
    position: absolute;
    left: 10px;
  }

  .selectGroup {
    align-self: self-end;
    margin: 0;
  }

  .selectGroup select {
    position: relative;
    font-size: 1.1rem;
    background: #fff;
    border: none;
  }
`;

export const LoadingContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 0.625rem;
`;
