import React, { FormEvent, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';

import {
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';

import { validateEmail } from '../../utils/validation';

import {
  removeSpecialCaracteres,
  removeSpecialCaractersFromPhoneNumber,
  cpfValidate,
} from 'utils/numberUtils';

import FormContext from 'context/FormContext';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import Success from './Success';

import hmLogo from '../../assets/hm-logo.svg';

import api from '../../services/emeserver/api';

import authToken from '../../services/emeserver/authToken';

import * as S from './styles';
import { toast, ToastContainer } from 'react-toastify';

interface clientProps {
  full_name: string;
  email: string;
  agent_id: number;
  agent_name: string;
}

export default function RegisterLead(): JSX.Element {
  const [fields, setFields] = useState({
    full_name: '',
    cpf: '',
    phone_number: '',
    email: '',
    agent_id: '',
    enterprise_slug: '',
    call_type: '',
    group_dashboard: '',
  });
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [success, setSuccess] = useState(false);
  const [client, setClient] = useState<clientProps>();
  const selectedAgent = JSON.parse(sessionStorage.getItem('selectedAgent'));

  const history = useHistory();

  async function onSubmit() {
    if (alreadyRegistered) {
      history.goBack();
      return;
    }

    console.log('cliente', client);

    const data = {
      full_name: client ? client.full_name : fields.full_name,
      cpf: fields.cpf,
      phone_number: fields.phone_number,
      email: client ? client.email : fields.email,
      agent_id: fields.agent_id,
      enterprise_slug: fields.enterprise_slug,
      call_type: fields.call_type,
      group_dashboard: selectedAgent.group_dashboard,
    };

    const createdCallRes = await api.post('/call/admin/create', data, {
      headers: {
        Authorization: authToken.token,
      },
    });

    if (createdCallRes.status === 201) {
      setSuccess(true);
    }
  }

  async function handleFetchRegisteredLead() {
    const res = await api.get(
      `/client/admin/find?cpf=${fields.cpf}&phone_number=${fields.phone_number}`,
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );

    if (res.data.response_data) {
      setClient(res.data.response_data);
      setAlreadyRegistered(true);
    }
  }

  return (
    <S.Container>
      <S.Card>
        <S.LogoContainer>
          <ReactSVG src={hmLogo} />
          <h1>
            Dashboard <span>Leads</span>
          </h1>
        </S.LogoContainer>

        {success ? (
          <Success />
        ) : (
          <S.Form>
            <FormikStepper
              initialValues={{
                cpf: '',
                phone_number: '',
                name: '',
                email: '',
                agent_id: 0,
                enterprise_slug: '',
                call_type: '',
              }}
              onSubmit={onSubmit}
              handleFetchRegisteredLead={handleFetchRegisteredLead}
              alreadyRegistered={alreadyRegistered}
              fields={fields}
            >
              <FormikStep label="Dados do lead">
                <FormContext.Provider
                  value={{
                    fields,
                    setFields,
                  }}
                >
                  <StepOne />
                </FormContext.Provider>
              </FormikStep>

              <FormikStep label="Dados do atendimento">
                <FormContext.Provider
                  value={{
                    fields,
                    setFields,
                  }}
                >
                  <StepTwo
                    alreadyRegistered={alreadyRegistered}
                    client={client}
                  />
                </FormContext.Provider>
              </FormikStep>
            </FormikStepper>
          </S.Form>
        )}
      </S.Card>
      <ToastContainer />
    </S.Container>
  );
}

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>;
}

export function FormikStepper({
  children,
  alreadyRegistered,
  fields,
  ...props
}: any) {
  const childrenArray = React.Children.toArray(
    children,
  ) as React.ReactElement<FormikStepProps>[];
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);

  const history = useHistory();

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  function handleCloseRegisterLeadModal() {
    history.goBack();
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);

          setCompleted(true);
        } else {
          if (step === 0) {
            const { cpf, phone_number } = fields;

            const cpfNumbers = removeSpecialCaracteres(cpf);
            const phoneNumbers =
              removeSpecialCaractersFromPhoneNumber(phone_number);

            if (!cpfValidate(cpfNumbers)) {
              return toast.error('CPF não possui um formato válido.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }

            if (phoneNumbers.length < 11) {
              return toast.error('Telefone não possui um formato válido.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }

            setTimeout(async () => {
              await props.handleFetchRegisteredLead();
              setStep((s) => s + 1);
            }, 1000);
          }
        }
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form autoComplete="off">
          {step === 0 && <h2 className="title-1 mb-4">Buscar lead</h2>}
          {step === 1 && !alreadyRegistered && (
            <h2 className="title-1 mb-4">Concluir cadastro</h2>
          )}
          <Stepper alternativeLabel activeStep={step}>
            {childrenArray.map((child, index) => (
              <Step
                key={child.props.label}
                completed={step > index || completed}
              >
                <StepLabel></StepLabel>
              </Step>
            ))}
          </Stepper>
          {currentChild}
          <div className="buttons-next">
            {step === 0 && (
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                onClick={() => handleCloseRegisterLeadModal()}
              >
                Cancelar
              </Button>
            )}

            {step > 0 && !alreadyRegistered ? (
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                onClick={() => setStep((s) => s - 1)}
              >
                Voltar
              </Button>
            ) : null}

            {!alreadyRegistered ? (
              <Button
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
                className={step === 1 ? 'red' : 'black'}
              >
                {isSubmitting
                  ? 'Enviando'
                  : isLastStep()
                  ? 'Cadastrar'
                  : 'Próximo'}
              </Button>
            ) : (
              <>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={'red'}
                  style={{ color: '#FFF' }}
                >
                  Fechar
                </Button>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
