import styled, { css, keyframes } from 'styled-components';
import { darken } from 'polished';

interface iAvaiableProps {
  available: boolean;
}

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  position: absolute;
  min-width: 40rem;
  min-height: 40rem;
  top: -1rem;
  right: -2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 0.4rem;
  box-shadow: 10px 10px 30px rgb(0 0 0 / 15%);
  padding: 2.5rem;
  z-index: 99;
  cursor: initial;
  animation: ${appearFromTop} 0.5s;
  @media (max-width: 768px) {
    min-width: 95%;
  }
`;

export const AgentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 2rem;
  border-bottom: 1px solid #f3f3f3;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 2rem;

    span {
      font-size: 1.2rem;
      color: #1d1d1b;
      font-weight: 800;
    }
  }
`;

export const AgentAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  margin-right: 1.5rem;
  border-radius: 50%;
  padding: 0.5rem;
  text-align: center;
  position: relative;

  span {
    display: block;
    border-radius: 50%;
    color: #fff;
    font-weight: 800;
    padding: 1.5rem 1.5rem;
    height: 5rem;
    width: 5rem;
    background: #1d1d1b;
    font-size: 1.6rem;

    @media (max-width: 768px) {
      height: 3rem;
      width: 3rem;
      padding: 0.7rem 0.7rem;
    }
  }
`;

export const AvailabilityIndicator = styled.div<iAvaiableProps>`
  height: 1.4rem;
  width: 1.4rem;
  background: #bf000b;
  border: 2px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  bottom: 0.5rem;

  ${(props) =>
    props.available &&
    css`
      background: #19cb9e;
    `}
`;

export const AgentName = styled.span`
  font-size: 1.6rem;
  color: #454545;
  font-weight: 800;
  margin-right: 1.5rem;
`;

export const AdminButtonsContainer = styled.div`
  margin: 2rem 0;
  padding-bottom: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #f3f3f3;
`;

export const RoundedButton = styled.button`
  background: #bf000b;
  height: 50px;
  border: none;
  border-radius: 6px;
  padding: 1rem;
  font-size: 1.4rem;
  margin: 1rem 0;
  width: 160px;
  &:hover {
    text-decoration: underline;
    background: ${darken(0.1, '#BF000B')};
  }
`;

export const RoundedButtonLabel = styled.span`
  font-size: 1.2rem;
  color: #fff;
  margin-left: 0.5rem;
  font-weight: 600;
`;

export const AvailabilityContainer = styled.div`
  width: 100%;
  margin-top: 2.5rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvailabilityLabel = styled.span`
  color: #757574, 100%;
  font-size: 1.2rem;
  width: 11.5rem;
  line-height: 1.6rem;
`;

interface AvailabilityButtonProps {
  available: boolean;
  isLoading: boolean;
}

export const AvailabilityButton = styled.button<AvailabilityButtonProps>`
  height: 4rem;
  width: 6.5rem;
  border-radius: 10rem;
  background-color: #f3f3f3;
  margin-left: 1.6rem;
  border: 0;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${(props) =>
    props.available &&
    css`
      justify-content: flex-end;
    `}

  ${(props) =>
    props.isLoading &&
    css`
      :disabled {
        background: #dddddd;
        cursor: initial;
      }
    `}
`;

interface iAvailabilityButtonIndicadorProps {
  available: boolean;
  isLoading: boolean;
}

export const AvailabilityButtonIndicador = styled.div<iAvailabilityButtonIndicadorProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #bf000b;
  box-shadow: 2px 2px 8px rgba(191, 0, 11, 0.2);

  ${(props) =>
    props.available &&
    css`
      background: #19cb9e;
      box-shadow: -2px 2px 8px rgba(21, 100, 80, 0.2);
    `}

  ${(props) =>
    props.isLoading &&
    css`
      background: #c7c7c7;
    `}
`;

export const AlertTurnOffContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 14rem;
  width: 22rem;
  border: 1px solid rgba(249, 175, 36, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 2rem;
  width: 100%;
`;

export const AlertTurnOffMessage = styled.span`
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: center;
  color: #454545;
  margin-top: 1rem;
  max-width: 18rem;
`;

export const LoadingContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

interface LogoutButtonProps {
  isLoading: boolean;
}

export const LogoutButton = styled.button<LogoutButtonProps>`
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: 0;
  background: none;

  ${(props) =>
    props.isLoading &&
    css`
      :disabled {
        background: #dddddd;
        cursor: initial;
        border-radius: 0.4rem;
      }
    `}
`;

export const LogoutLabel = styled.span`
  margin-left: 1rem;
`;
